<template>
    <b-card no-body class="px-4">
        <template>
            <Header />
            <hr class="my-3" />
        </template>
        <b-form @submit.prevent="save" class="account-form d-flex flex-column" style="row-gap: 12px">
            <fieldset class="">
                <h4 class="mb-2">Loan Information</h4>
                <div class="row" style="row-gap: 12px">
                    <div class="col-4">
                        <label for="DDLTypeLoan" class="mb-1">Loan Type</label>
                        <b-form-select
                            size="sm"
                            v-model="loanType"
                            id="DDLTypeLoan"
                            name="DDLTypeLoan"
                            v-on:change="getMinAndMaxAmount()"
                            required
                        >
                            <b-form-select-option :value="null">Please select an option</b-form-select-option>
                            <b-form-select-option value="1">Personal</b-form-select-option>
                            <b-form-select-option value="2">Vehicle</b-form-select-option>
                        </b-form-select>
                    </div>
                    <div class="col-8"></div>

                    <div class="col-4">
                        <label for="LoanAmount" class="mb-1">Amount</label>
                        <b-input
                            size="sm"
                            type="number"
                            class="WhitePlaceHolder"
                            id="LoanAmount"
                            name="LoanAmount"
                            v-model="amount"
                            v-bind:min="minAmount"
                            v-bind:max="maxAmount"
                            v-on:input="debounce()"
                            placeholder=""
                            required
                        />
                        <span class="span" v-if="minAmount && maxAmount">Range: {{ minAmount }} - {{ maxAmount }}</span>
                    </div>

                    <div class="col-4">
                        <label for="DDLPeriod" class="mb-1">Installments</label>
                        <b-form-select
                            size="sm"
                            v-model="monthSelectedOption"
                            id="DDLPeriod"
                            name="DDLPeriod"
                            v-on:change="getMonthlyPayment()"
                            required
                        >
                            <b-form-select-option :value="null">Please select an option</b-form-select-option>
                            <b-form-select-option v-for="month in monthsArray" :key="month" :value="month">{{
                                month
                            }}</b-form-select-option>
                        </b-form-select>
                    </div>
                    <div class="col-4">
                        <label for="MonthlyPayment" class="mb-1">
                            Monthly Payment
                            <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                        </label>
                        <b-input
                            size="sm"
                            type="number"
                            class="WhitePlaceHolder"
                            id="MonthlyPayment"
                            name="MonthlyPaCment"
                            v-model="monthlyPayment"
                            placeholder="AWG 0.00"
                            readonly
                        />
                    </div>

                    <div class="col-4">
                        <label for="fee" class="mb-1">
                            Expected administration fee
                            <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                        </label>
                        <b-input
                            size="sm"
                            type="number"
                            class="WhitePlaceHolder"
                            id="fee"
                            name="MonthlyPaCment"
                            :value="(amount * 2.5) / 100"
                            placeholder="AWG 0.00"
                            readonly
                        />
                    </div>

                    <div class="col-4">
                        <label for="fee" class="mb-1"> Total expected amount </label>
                        <b-input
                            size="sm"
                            type="number"
                            class="WhitePlaceHolder"
                            id="fee"
                            name="MonthlyPaCment"
                            :value="amount + (amount * 2.5) / 100"
                            placeholder="AWG 0.00"
                            readonly
                        />
                    </div>

                    <div class="col-4">
                        <label for="fee" class="mb-1"> APR </label>
                        <b-input
                            size="sm"
                            type="text"
                            class="WhitePlaceHolder"
                            id="fee"
                            v-model="apr"
                            name="MonthlyPaCment"
                            placeholder="AWG 0.00"
                            readonly
                        />
                    </div>
                </div>
            </fieldset>

            <fieldset class="">
                <div class="row" style="row-gap: 12px">
                    <div class="col-4">
                        <label for="repayment" class="mb-1"> Repayment Method </label>
                        <!-- select -->
                        <b-form-select v-model="repaymentMethod" size="sm" id="repayment" name="repayment" required>
                            <b-form-select-option value="1">CASH</b-form-select-option>
                            <b-form-select-option value="2">POB</b-form-select-option>
                        </b-form-select>
                    </div>

                    <b-col sm="4" class="">
                        <label class="fw-medium mb-1" for="comp4">Purpose</label>
                        <b-form-group class="mb-0">
                            <b-form-select
                                size="sm"
                                required
                                :options="[{ id: null, description: 'Please select an option' }, ...purposes]"
                                text-field="description"
                                value-field="id"
                                v-model="purpose"
                            ></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4" class="">
                        <label class="fw-medium mb-1" for="">Purpose Details</label>
                        <b-input
                            size="sm"
                            type="text"
                            id="purposeDetails"
                            name="purposeDetails"
                            v-model="purposeDetails"
                        />
                    </b-col>
                </div>
            </fieldset>

            <fieldset class="">
                <div class="row">
                    <div class="col-4">
                        <label class="fw-medium mb-1" for="cus4">Reason Type</label>
                        <b-form-radio-group
                            v-model="reasonType"
                            :options="customersTypes"
                            class="d-flex"
                            value-field="id"
                            text-field="name"
                            required
                        ></b-form-radio-group>
                    </div>
                    <div v-if="reasonType == 2" class="col-4">
                        <label for="fee" class="mb-1"> Business Name </label>
                        <b-input
                            size="sm"
                            v-model="businessName"
                            type="text"
                            class="WhitePlaceHolder"
                            id="fee"
                            name="MonthlyPaCment"
                            placeholder="Business"
                        />
                    </div>
                </div>
            </fieldset>

            <fieldset class="">
                <h4 class="mb-2">Customer Information</h4>
                <div class="row">
                    <div class="col-4">
                        <div class="mb-2">
                            <label>Search an applicant</label>
                            <SearchSelect
                                store="customer/get_allCustomers"
                                text="firstName"
                                value="id"
                                :defaultOption="applicant"
                                @option-selected="handleOption"
                            />
                        </div>
                    </div>

                    <div class="col-4">
                        <div class="mb-2">
                            <label>Search a co-applicant</label>
                            <SearchSelect
                                store="customer/get_allCustomers"
                                text="firstName"
                                value="id"
                                :defaultOption="coApplicant"
                                @option-selected="handleOptioCoApplicant"
                            />
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="mb-2">
                            <label>Search an guarantor</label>
                            <SearchSelect
                                store="customer/get_allCustomers"
                                text="firstName"
                                value="id"
                                :defaultOption="guarantor"
                                @option-selected="handleOptionGuarantor"
                            />
                        </div>
                    </div>
                </div>
            </fieldset>

            <div class="row mt-4">
                <div class="col-md-12 d-flex justify-content-end align-items-start">
                    <b-button type="submit" id="BtnSendForm" variant="primary" :disabled="loadingData">
                        <b-spinner small v-if="loadingData"></b-spinner>
                        <span v-else>Save</span>
                    </b-button>
                </div>
            </div>
        </b-form>
    </b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchSelect from '../../components/SearchSelect.vue';
import Header from '../customers/Header.vue';

export default {
    name: 'Apply',
    components: {
        SearchSelect,
        Header,
    },
    data() {
        return {
            loanType: null,
            amount: null,
            monthsArray: [],
            monthSelectedOption: null,
            minAmount: null,
            maxAmount: null,
            monthlyPayment: 0,
            loading: false,
            loadingData: false,
            purpose: null,
            customers: [],
            applicant: null,
            coApplicant: null,
            guarantor: null,
            purposes: [],
            reasonType: null,
            businessName: null,
            repaymentMethod: null,
            purposeDetails: null,
            purposeDescription: null,
            apr: null,
        };
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            customersTypes: 'general/customersTypes/getcustomersTypes',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
    },
    methods: {
        async save() {
            const payload = {
                id: this.loanApplication.id,
                loanTypeId: this.loanType,
                periodInMonths: this.monthSelectedOption,
                loanAmount: this.amount,
                repaymentMethod: this.repaymentMethod,
                purposeId: this.purpose,
                purposeDetails: this.purposeDetails,
                purposeDescription: this.purposeDescription,
                customerId: this.applicant?.id,
                coApplicantId: this.coApplicant?.id,
                guarantorId: this.guarantor?.id,
                modifiedBy: this.user.id,
                origin: 1,
                isActive: true,
            };

            this.loadingData = true;
            await this.$store.dispatch('loan/applications/update_loanApplication', payload);
            this.loadingData = false;
        },

        async getMinAndMaxAmount() {
            const response = await this.$store.dispatch('loan/applications/get_loanRange', this.loanType);
            this.minAmount = response.minAmount;
            this.maxAmount = response.maxAmount;
            await this.getMonths();
            this.monthSelectedOption = this.monthsArray[0];
            await this.getMonthlyPayment();
        },

        async getMonths() {
            const payload = {
                amount: this.amount,
                loanType: this.loanType,
            };
            const response = await this.$store.dispatch('loan/applications/get_monthsByLoanType', payload);
            if (!response && response.length < 1) return;
            this.monthsArray = response;
            this.monthSelectedOption = response[0];
            this.getMonthlyPayment();
        },
        async getMonthlyPayment() {
            this.loading = true;
            const payload = {
                amount: this.amount,
                months: this.monthSelectedOption,
                loanType: this.loanType,
            };
            const response = await this.$store.dispatch('loan/applications/get_monthlyPayment', payload);
            this.monthlyPayment = response.amount;
            this.apr = response.apr;
            this.loading = false;
        },
        debounce() {
            if (this.loanType == null) {
                this.$bvToast.toast('Please select a loan type.', {
                    title: 'Please!',
                    variant: 'warning',
                    solid: true,
                });
                return;
            }

            this.loading = true;
            clearTimeout(this.inputTimer);

            const validateAmount = () => {
                let loanTypeDescription = this.loanType == 1 ? 'Personal' : 'Vehicle';

                if (this.amount < this.minAmount) {
                    this.$bvToast.toast(`Our ${loanTypeDescription} Loans start at AWG ${this.minAmount}.`, {
                        title: 'Please!',
                        variant: 'warning',
                        solid: true,
                    });
                    this.amount = this.minAmount;
                } else if (this.amount > this.maxAmount) {
                    this.$bvToast.toast(
                        `You have exceeded the maximum loan amount. Our ${loanTypeDescription} Loans go up to ${this.maxAmount}.`,
                        {
                            title: 'Please!',
                            variant: 'warning',
                            solid: true,
                        },
                    );
                    this.amount = this.maxAmount;
                }
            };

            this.inputTimer = setTimeout(async () => {
                if (this.amount < this.minAmount || this.amount > this.maxAmount) {
                    validateAmount();
                }

                await this.getMonths();
                this.monthSelectedOption = this.monthsArray[0];
                this.getMonthlyPayment();
            }, 1500);
        },

        handleOption(item) {
            if (item) {
                this.customer = item;
            }
        },
        handleOptioCoApplicant(item) {
            if (item) {
                this.coApplicant = item;
            }
        },
        handleOptionGuarantor(item) {
            if (item) {
                this.guarantor = item;
            }
        },

        async getPurposes() {
            const response = await this.$store.dispatch('loan/applications/get_purposes');
            this.purposes = response?.dataResult ?? [];
        },
        async getCustomerTypes() {
            await this.$store.dispatch('general/customersTypes/get_allCustomersTypes', { skip: 1, take: 9999 });
        },
        setData() {
            this.loanType = this.loanApplication.loanTypeId;
            this.amount = this.loanApplication.loanAmount;
            this.monthSelectedOption = this.loanApplication.periodInMonths;
            this.repaymentMethod = this.loanApplication.repaymentMethod;
            this.purpose = this.loanApplication.purposeId;
            this.purposeDetails = this.loanApplication.purposeDetails;
            this.purposeDescription = this.loanApplication.purposeDescription;
            this.monthlyPayment = this.loanApplication.monthlyPayment;

            const applicantInfo = this.loanApplication.applicantInfo;
            this.reasonType = applicantInfo.reasonTypeId;
            this.applicant = applicantInfo;

            this.coApplicant =
                this.loanApplication.coApplicantInfo.id != 0 ? this.loanApplication.coApplicantInfo : null;
            this.guarantor = this.loanApplication.guarantorInfo.id != 0 ? this.loanApplication.guarantorInfo : null;

            if (this.reasonType == 2) {
                this.businessName = applicantInfo.businessName;
            }
            this.getMinAndMaxAmount();
            this.getMonths();
            this.getMonthlyPayment();
        },
    },
    created() {
        this.getPurposes();
        this.getCustomerTypes();
        this.setData();
    },
};
</script>
