<template>
    <div class="p-3 bg-light" v-if="customer && customer.id != 0">
        <div class="d-flex align-items-center justify-content-between mb-2">
            <h3 class="mb-0">{{ title }}</h3>
            <div class="d-flex align-items-center">
                <b-spinner small v-if="loadingPep || loading" class="mr-2"></b-spinner>
                <b-dropdown v-if="hasPermission" variant="primary" id="dropdown-1" text="Actions" class="">
                    <b-dropdown-item @click="updatePepData">Save</b-dropdown-item>
                    <b-dropdown-item @click="validateMember">Verify</b-dropdown-item>
                    <MemberCheckReport :scans="scans" />
                </b-dropdown>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-4">
                <div class="mb-4 bg-white rounded-lg p-3 h-100">
                    <div class="d-flex justify-content-between align-items-start mb-2">
                        <b-badge :variant="'success'">Low Risk </b-badge>
                    </div>
                    <div class="row" v-if="customer">
                        <div class="col-12">
                            <h6 class="mb-0">Name</h6>
                            <p class="text-muted mb-1">{{ customer.firstName }} {{ customer.lastName }}</p>
                        </div>
                        <div class="col-6">
                            <h6 class="mb-0">Adress</h6>
                            <p class="text-muted mb-1">{{ customer.address1 }}</p>
                        </div>
                        <div class="col-6">
                            <h6 class="mb-0">Date of birth</h6>
                            <p class="text-muted mb-1" v-if="customer.additionalDetails">
                                {{ customer.additionalDetails.birthDate.split('T')[0] }}
                            </p>
                        </div>
                        <div class="col-6">
                            <h6 class="mb-0">City</h6>
                            <p class="text-muted mb-1">{{ customer.cityName }}</p>
                        </div>
                        <div class="col-6">
                            <h6 class="mb-0">Nationality</h6>
                            <p class="text-muted mb-1" v-if="customer.additionalDetails">
                                {{ customer.additionalDetails.nationality }}
                            </p>
                        </div>
                        <div class="col-6">
                            <h6 class="mb-0">Marital Status</h6>
                            <p class="text-muted mb-1" v-if="maritalStatus">
                                {{ maritalStatus ? maritalStatus.name : '' }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="bg-white p-2 mb-2 rounded-lg h-100" v-if="!readOnly">
                    <p class="mb-0 text-muted mb-2">
                        Last verification date:
                        {{ formattedDate }}
                    </p>

                    <div class="">
                        <div>
                            <b-form-checkbox size="sm" v-model="pep" class="">
                                Politically exposed person
                            </b-form-checkbox>

                            <b-form-group v-slot="{ ariaDescribedby }" class="mb-3">
                                <label class="fw-medium mb-1">Validate actual matches</label>
                                <b-form-radio-group
                                    size="sm"
                                    :aria-describedby="ariaDescribedby"
                                    v-model="pepConfirm"
                                    :value="true"
                                >
                                    <b-form-radio :value="true">True coincidence</b-form-radio>
                                    <b-form-radio :value="false">Non-truthful coincidence</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>

                            <div class="row mb-3" v-if="pepConfirm">
                                <div class="col-4">
                                    <label class="fw-medium mb-1" for="idType">Identification Type</label>
                                    <b-form-select
                                        size="sm"
                                        required
                                        v-model="idType"
                                        :options="[
                                            { value: null, text: 'Please select an option' },
                                            { text: 'Passport', value: 'passport' },
                                            { text: 'ID', value: 'id' },
                                        ]"
                                    ></b-form-select>
                                </div>
                                <div class="col-4">
                                    <label class="fw-medium mb-1" for="identification">No Identification</label>
                                    <b-form-input
                                        size="sm"
                                        type="text"
                                        placeholder="Number of ID"
                                        required
                                        v-model="identification"
                                    ></b-form-input>
                                </div>
                                <div class="col-4">
                                    <label class="fw-medium mb-1" for="exp">Expiration Date</label>
                                    <b-form-input
                                        size="sm"
                                        type="date"
                                        placeholder="Exp. date for document"
                                        required
                                        v-model="expDate"
                                    ></b-form-input>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <label class="fw-medium mb-1" for="risk">Type of risk</label>
                                    <b-form-select
                                        size="sm"
                                        v-model="typeRisk"
                                        required
                                        :options="[
                                            { value: null, text: 'Please select an option' },
                                            { text: 'Low risk', value: 1 },
                                            { text: 'Medium risk', value: 2 },
                                            { text: 'High risk', value: 3 },
                                        ]"
                                    ></b-form-select>
                                </div>
                                <div class="col-6">
                                    <label for="description" class="fw-medium mb-1">Describe your decision</label>
                                    <b-form-textarea
                                        size="sm"
                                        placeholder="Describe decision..."
                                        rows="3"
                                        max-rows="6"
                                        v-model="decision"
                                    ></b-form-textarea>
                                </div>
                                <div class="col-12 d-flex justify-content-end">
                                    <b-button variant="primary" @click="updatePepData" class="mt-3"> Save </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="scans.length <= 0">
            <div class="col-12 py-5">
                <h5 class="text-center">There are no records to show</h5>
            </div>
        </div>

        <div class="row" style="max-height: 600px; overflow-y: auto; overflow-x: visible">
            <div class="col-12 mb-2" v-for="item in scans" :key="item.id">
                <article class="bg-white h-100 rounded-lg p-3">
                    <div class="d-flex justify-content-between align-items-center border-bottom pb-1 mb-1">
                        <h4 class="mb-0">UID #{{ item.uniqueId }}</h4>
                        <span class="bg-light p-1 text-dark rounded-lg"
                            >Match Rate | <strong>{{ item.matchRate }}</strong></span
                        >
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <div class="row">
                                <div class="col-6">
                                    <div class="py-2 d-flex justify-content-start align-items-center">
                                        <span class="bg-light mr-1 p-1 rounded-circle d-flex align-items-center">
                                            <feather type="user" class="feather-sm"></feather>
                                        </span>
                                        <div>
                                            <h6 class="mb-0">Name</h6>
                                            <p class="mb-0">{{ item.primaryFirstName }} {{ item.primaryLastName }}</p>
                                        </div>
                                    </div>
                                    <div class="py-2 d-flex justify-content-start align-items-center">
                                        <span class="bg-light mr-1 p-1 rounded-circle d-flex align-items-center">
                                            <feather type="map-pin" class="feather-sm"></feather>
                                        </span>
                                        <div>
                                            <h6 class="mb-0">Primary Location</h6>
                                            <p class="mb-0">{{ item.primaryLocation }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="py-2 d-flex justify-content-start align-items-center">
                                        <span class="bg-light mr-1 p-1 rounded-circle d-flex align-items-center">
                                            <feather type="info" class="feather-sm"></feather>
                                        </span>
                                        <div>
                                            <h6 class="mb-0-">Deceased</h6>
                                            <p class="mb-0">{{ item.deceased }}</p>
                                        </div>
                                    </div>
                                    <div class="py-2 d-flex justify-content-start align-items-center">
                                        <span class="bg-light mr-1 p-1 rounded-circle d-flex align-items-center">
                                            <feather type="user-check" class="feather-sm"></feather>
                                        </span>
                                        <div>
                                            <h6 class="mb-0">Gender</h6>
                                            <p class="mb-0">{{ item.gender }}</p>
                                        </div>
                                    </div>
                                    <div class="py-2 d-flex justify-content-start align-items-center">
                                        <span class="bg-light mr-1 p-1 rounded-circle d-flex align-items-center">
                                            <feather type="calendar" class="feather-sm"></feather>
                                        </span>
                                        <div>
                                            <h6 class="mb-0">Date of Birth</h6>
                                            <p class="mb-0">{{ item.dateOfBirth }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-8">
                            <div class="p-2 bg-light rounded-lg my-1">
                                <h6 class="mb-1">Categories Details</h6>
                                <p class="mb-0">{{ item.categories }}</p>
                            </div>

                            <div class="p-2 bg-light rounded-lg my-1">
                                <h6 class="mb-1">Further Information</h6>
                                <p class="mb-0">{{ item.furtherInformation }}</p>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MemberCheckReport from '../reports/MemberCheckReport.vue';
import * as dayjs from 'dayjs';

export default {
    name: 'MemberCheck',
    props: {
        new: Boolean,
        readOnly: Boolean,
        type: String,
        title: String,
    },
    components: {
        MemberCheckReport,
    },
    data: () => ({
        firstName: '',
        lastName: '',
        loading: false,
        selectedMatch: null,
        loadingPep: false,
        maritalStatusArray: [
            {
                id: 1,
                name: 'Casa',
            },
            {
                id: 2,
                name: 'Soltero/-a',
            },
            {
                id: 3,
                name: 'Biba Hunto',
            },
            {
                id: 4,
                name: 'Otro',
            },
        ],
        pep: false,
        pepConfirm: null,
        typeRisk: null,
        decision: '',
        idType: null,
        identification: '',
        expDate: '',
    }),
    computed: {
        ...mapGetters({
            authorizations: 'auth/getAuthorizations',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        maritalStatus() {
            return this.maritalStatusArray.find((el) => el.id == this.customer?.additionalDetails?.maritalStatusId);
        },
        hasPermission() {
            return this.authorizations.includes('/LoanApplications-Membercheck');
        },
        formattedDate() {
            if (this.customer.scansLastVerificationDate) {
                return this.formatDate(this.customer.scansLastVerificationDate);
            }
            return 'N/D';
        },
        scans() {
            if (this.type === 'applicant') {
                return this.loanApplication?.applicantInfo?.membercheckResults ?? [];
            }
            if (this.type === 'coApplicant') {
                return this.loanApplication?.coApplicantInfo?.membercheckResults ?? [];
            }
            if (this.type === 'guarantor') {
                return this.loanApplication?.guarantorInfo?.membercheckResults ?? [];
            }
            return [];
        },
    },
    methods: {
        formatDate(date) {
            if (!date) return;

            return dayjs(date).format('YYYY-MM-DD');
        },
        async validateMember() {
            this.loading = true;
            await this.$store.dispatch('customer/scans/createReview', {
                firstName: this.firstName,
                lastName: this.lastName,
                customerId: this.customer.id,
                loanApplicationId: this.$route.query.loanApplicationId,
            });
            await this.$store.dispatch('customer/get_customer', this.id);
            await this.getData();
            this.firstName = '';
            this.lastName = '';
            this.loading = false;
        },
        async getData() {
            this.loading = true;
            await this.$store.dispatch(
                'customer/productRequest/get_processResquest',
                this.$route.query.loanApplicationId,
            );
            this.loading = false;
        },

        setData() {
            if (this.type === 'applicant') {
                this.customer = this.loanApplication?.applicantInfo;
            }
            if (this.type === 'coApplicant') {
                this.customer = this.loanApplication?.coApplicantInfo;
            }
            if (this.type === 'guarantor') {
                this.customer = this.loanApplication?.guarantorInfo;
            }

            this.firstName = this.customer.firstName;
            this.lastName = this.customer.lastName;
            this.typeRisk = this.customer.riskTypeId;
            this.pep = this.customer.pep;
            this.pepConfirm = this.customer.peP_Confirmed;

            if (!this.customer.peP_Form) return;

            const pepObj = JSON.parse(this.customer.peP_Form);
            if (pepObj) {
                this.idType = pepObj.IdentificationTypeId;
                this.identification = pepObj.IDNumber;
                this.expDate = dayjs(pepObj.ExpirationDate).format('YYYY-MM-DD');
                this.decision = pepObj.DecisionSummary;
            }
        },

        async updatePepData() {
            const documents = [
                { text: 'Passport', value: 'passport' },
                { text: 'ID', value: 'id' },
            ];
            if (!this.idType) {
                this.$bvToast.toast('Please select an identification type', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                return;
            }

            const idName = documents.find((el) => el.value == this.idType);
            let pepForm = null;

            if (this.pepConfirm) {
                pepForm = {
                    identificationTypeId: this.idType,
                    identificationTypeName: idName.text,
                    idNumber: this.identification,
                    expirationDate: this.expDate,
                    decisionSummary: this.decision,
                };
            }

            let payload = {
                customerId: this.customer.id,
                riskTypeId: this.typeRisk,
                pep: this.pep,
                peP_Confirmed: this.pepConfirm,
                pepForm,
            };

            await this.$store.dispatch('customer/update_pepInformation', payload);
        },
    },
    watch: {
        pep: function (value) {
            if (!value) {
                this.typeRisk = 1;
                return;
            }
            if (value && this.pepConfirm) {
                this.typeRisk = 3;
            }
            if (value && !this.pepConfirm) {
                this.typeRisk = 2;
            }
        },
        pepConfirm: function (value) {
            if (value && this.pep) {
                this.typeRisk = 3;
            }
            if (!value && this.pep) {
                this.typeRisk = 2;
            }
            if (!value && !this.pep) {
                this.typeRisk = 1;
            }
        },
    },
    created() {
        this.getData();
        this.setData();
    },
};
</script>
