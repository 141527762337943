<template>
    <b-overlay :show="loading" rounded="sm">
        <div>
            <b-card no-body class="">
                <template v-if="!readOnly">
                    <Header />
                    <hr class="mb-4" />
                </template>
                <!-- Search Control -->
                <div class="mb-2 d-flex justify-content-between">
                    <b-form-group class="mb-2 w-25">
                        <b-input-group>
                            <b-form-input
                                id="filter-input"
                                v-model="filter"
                                type="search"
                                placeholder="Type to Search"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <div class="d-flex align-items-center">
                        <b-button
                            variant="success"
                            class="mr-2"
                            @click="submitToVerification"
                            :disabled="loadingSendVerifications || screening.length === 0"
                        >
                            <b-spinner small v-if="loadingSendVerifications"></b-spinner>
                            Submit to screening verification
                        </b-button>
                        <b-button variant="primary" class="mr-2" v-b-modal.modal-add-obligation v-if="hasPermission">
                            Add obligation
                        </b-button>
                    </div>
                </div>
                <!-- End Section -->
                <!-- Data Table -->
                <b-table
                    style="min-height: 250px"
                    :per-page="perPage"
                    :current-page="currentPage"
                    show-empty
                    responsive
                    small
                    class="mb-0"
                    head-variant="light"
                    :items="screening"
                    :filter="filter"
                    :fields="fields"
                    hover
                >
                    <template #cell(id)="data"> #{{ data.item.id }} </template>
                    <template #cell(formIsCompleted)="data">
                        <b-badge variant="success" v-if="data.item.formIsCompleted">Completed</b-badge>
                        <b-badge variant="danger" v-else>Pending</b-badge>
                    </template>
                    <template #cell(hasDebt)="data">
                        <b-avatar variant="success" class="text-white mr-2" :size="24" v-if="data.item.hasDebt">
                            <feather type="check" class="feather-sm"></feather>
                        </b-avatar>
                        <b-avatar variant="danger" class="text-white mr-2" :size="24" v-else>
                            <feather type="x" class="feather-sm"></feather>
                        </b-avatar>
                    </template>
                    <template #cell(emailSent)="data">
                        <b-badge variant="success" v-if="data.item.emailSent">Email Sent</b-badge>
                        <b-badge variant="danger" v-else>Pending</b-badge>
                    </template>
                    <template #cell(isActive)="data">
                        <b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
                        <b-badge variant="danger" v-else>Inactive</b-badge>
                    </template>
                    <template #cell(action)="data">
                        <b-dropdown variant="primary" text="Actions" class="">
                            <b-dropdown-item v-if="data.item.isActive" @click="disabledVerification(data.item)"
                                >Disable</b-dropdown-item
                            >
                            <b-dropdown-item v-else @click="enableVerification(data.item)">Enable</b-dropdown-item>
                            <b-dropdown-item @click="getLink(data.item)">Get Link</b-dropdown-item>
                        </b-dropdown>
                    </template>
                </b-table>
                <!-- End Section -->
                <div class="p-3 d-flex align-items-center">
                    <b-form-group
                        label="Per page"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="9"
                        label-size="sm"
                        label-class="fw-medium"
                        class="mb-0"
                    >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                        ></b-form-select>
                    </b-form-group>
                    <div class="ml-auto">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="screening.length"
                            :per-page="perPage"
                            align="fill"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
            </b-card>

            <b-modal id="modal-add-obligation" hide-footer centered title="Financial Obligations" size="md">
                <FinancialObligations />
            </b-modal>

            <!-- Modal disclaimer send Verifications -->
            <b-modal id="modal" hide-footer centered title="Confirm" size="sm">
                <b-card-text> Are you sure to want to sent verifications? </b-card-text>
                <div class="d-flex justify-content-end">
                    <b-button variant="secondary" class="mr-2" @click="$bvModal.hide('modal')">No</b-button>
                    <b-button variant="primary" @click="sendAllVerifications">
                        <b-spinner small v-if="loadingSendVerifications"></b-spinner>
                        Yes
                    </b-button>
                </div>
            </b-modal>

            <!-- Modal disable -->
            <b-modal id="modal-disable-screening" hide-footer centered title="Disable">
                <b-card-text> Are you sure to want to disable this verification? </b-card-text>
                <div class="d-flex justify-content-end">
                    <b-button variant="danger" class="mr-2" @click="updateStatus(false)">Yes</b-button>
                    <b-button variant="secondary" @click="$bvModal.hide('modal-disable-screening')">No</b-button>
                </div>
            </b-modal>

            <!-- Modal enable -->
            <b-modal id="modal-enable-screening" hide-footer centered title="Enable">
                <b-card-text> Are you sure to want to enable this verification? </b-card-text>
                <div class="d-flex justify-content-end">
                    <b-button variant="danger" class="mr-2" @click="updateStatus(true)">Yes</b-button>
                    <b-button variant="secondary" @click="$bvModal.hide('modal-enable-screening')">No</b-button>
                </div>
            </b-modal>
        </div>
    </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/customers/Header.vue';
import FinancialObligations from '@/components/applications/FinancialObligations.vue';
export default {
    name: 'Customer',
    components: {
        Header,
        FinancialObligations,
    },
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        page: {
            title: 'SeachTable',
        },
        filter: null,
        fields: [
            {
                key: 'bankId',
                label: 'ID',
                sortable: true,
            },
            {
                key: 'bankDescription',
                label: 'Bank Description',
                sortable: true,
            },
            {
                key: 'CustomerType',
                label: 'Customer Type',
            },
            {
                key: 'amount',
                label: 'Amount',
            },
            {
                key: 'montlyPayment',
                label: 'Monthly Payment',
            },
            {
                key: 'emailSent',
                label: 'Email Sent',
            },
            {
                key: 'formIsCompleted',
                label: 'Verified',
            },
            {
                key: 'createdByUserName',
                label: 'Created By',
            },
            {
                key: 'isActive',
                label: 'Status',
                sortable: true,
            },
        ],
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        screeningSelected: null,
        loading: false,
        loadingSendVerifications: false,
        loadingUpdateStatus: false,
    }),
    methods: {
        async getScreeningVerifications() {
            this.loading = true;
            await this.$store.dispatch('loan/applications/refreshApplicantsInfo', this.$route.query.loanApplicationId);
            this.loading = false;
        },
        async sendAllVerifications() {
            this.loadingSendVerifications = true;
            await this.$store.dispatch('customer/screening/send_Emails', this.$route.query.loanApplicationId);
            await this.getScreeningVerifications();
            this.loadingSendVerifications = false;
        },
        async updateStatus(status) {
            this.loadingUpdateStatus = true;
            await this.$store.dispatch('customer/screening/update_screeningStatus', {
                id: this.screeningSelected.id,
                isActive: status,
            });
            await this.getScreeningVerifications();
            this.screeningSelected = null;
            this.$bvModal.hide('modal-disable-screening');
            this.$bvModal.hide('modal-enable-screening');
            this.loadingUpdateStatus = false;
        },
        getLink(item) {
            //copy to clipboard
            const url = `${window.location.origin}/screening-verification/${item.guid}?id=${item.id}`;
            if (navigator.clipboard) {
                navigator.clipboard.writeText(url);
                this.$bvToast.toast(`Link copied to clipboard`, {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
            }
        },
        disabledVerification(item) {
            this.screeningSelected = item;
            this.$bvModal.show('modal-disable-screening');
        },
        enableVerification(item) {
            this.screeningSelected = item;
            this.$bvModal.show('modal-enable-screening');
        },

        async submitToVerification() {
            this.loadingSendVerifications = true;
            console.log(this.$route.params.customer);
            await this.$store.dispatch('customer/screening/submit_toPendingRequests', this.$route.params.customer);
            this.loadingSendVerifications = false;
        },
    },
    computed: {
        ...mapGetters({
            authorizations: 'auth/getAuthorizations',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        hasPermission() {
            return this.authorizations.includes('/LoanApplications-Screening');
        },
        screening() {
            return this.loanApplication?.screening ?? [];
        },
    },
    mounted() {},
    created() {},
};
</script>
