<template>
    <b-card no-body>
        <template v-if="!readOnly">
            <Header />
            <hr class="mb-2" />
        </template>
        <div class="d-flex flex-column" style="row-gap: 12px">
            <MemberDetail type="applicant" title="Customer Information" />
            <MemberDetail type="coApplicant" title="Co-Applicant Information" />
            <MemberDetail type="guarantor" title="Guarantor Information" />
        </div>
    </b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/customers/Header.vue';
import MemberDetail from './MemberDetail.vue';
export default {
    components: {
        Header,
        MemberDetail,
    },
    name: 'MemberCheck',
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        firstName: '',
        lastName: '',
        loading: false,
        scans: [],
        selectedMatch: null,
        loadingPep: false,
        maritalStatusArray: [
            {
                id: 1,
                name: 'Casa',
            },
            {
                id: 2,
                name: 'Soltero/-a',
            },
            {
                id: 3,
                name: 'Biba Hunto',
            },
            {
                id: 4,
                name: 'Otro',
            },
        ],
        pep: false,
        pepConfirm: null,
        typeRisk: null,
        decision: '',
        idType: null,
        identification: '',
        expDate: '',
    }),
    computed: {
        ...mapGetters({
            customer: 'customer/getCustomer',
            authorizations: 'auth/getAuthorizations',
        }),
        hasPermission() {
            return this.authorizations.includes('/LoanApplications-Membercheck');
        },
    },
    methods: {},
};
</script>
