<template>
    <b-overlay :show="loading" rounded="sm">
        <b-card no-body class="px-4">
            <div style="row-gap: 20px; display: flex; flex-direction: column">
                <template v-if="!readOnly">
                    <Header />
                    <hr class="m-0" />
                </template>

                <!-- ID Info Section -->
                <b-card-body class="p-2 border-bottom" v-if="applicant">
                    <div class="d-flex justify-content-between pb-2">
                        <h4 class="">CUSTOMER INFO</h4>
                        <b-button size="sm" @click="goToApplicantDetail" variant="primary">View Details </b-button>
                    </div>

                    <div class="row" style="row-gap: 12px">
                        <div class="col-3">
                            <label class="fw-medium mb-0" for="fname4">PROFILE CREATED</label>
                            <ItemText :text="applicant.createDate" isDate />
                        </div>
                        <div class="col-3">
                            <label class="fw-medium mb-0" for="fname4">LASTNAME</label>
                            <ItemText :text="applicant.lastName" />
                        </div>
                        <div class="col-3">
                            <label class="fw-medium mb-0" for="fname4">MIDDLENAME</label>
                            <ItemText :text="applicant.middleName" />
                        </div>
                        <div class="col-3">
                            <label class="fw-medium mb-0" for="fname4">FIRSTNAME</label>
                            <ItemText :text="applicant.firstName" />
                        </div>
                        <div class="col-3">
                            <label class="fw-medium mb-0" for="fname4">TITLE</label>
                            <ItemText :text="applicant.titleDescription" />
                        </div>
                        <div class="col-3">
                            <label class="fw-medium mb-0" for="fname4">ADDRESS</label>
                            <ItemText :text="applicant.address1" />
                        </div>
                        <div class="col-3">
                            <label class="fw-medium mb-0" for="fname4">DISTRICT</label>
                            <ItemText :text="applicant.cityName" />
                        </div>
                    </div>
                </b-card-body>
                <!-- End Section -->

                <b-card-body class="p-2 border-bottom">
                    <h4 class="">CONTACT INFO</h4>
                    <div class="row">
                        <div class="col-3">
                            <label class="fw-medium mb-0" for="fname4">PHONE 1</label>
                            <ItemText :text="applicant.phone1" />
                        </div>
                        <div class="col-3">
                            <label class="fw-medium mb-0" for="fname4">PHONE 2</label>
                            <ItemText :text="applicant.phone2" />
                        </div>
                        <div class="col-3">
                            <label class="fw-medium mb-0" for="fname4">EMAIL 1</label>
                            <ItemText :text="applicant.email1" />
                        </div>
                        <div class="col-3">
                            <label class="fw-medium mb-0" for="fname4">EMAIL 2</label>
                            <ItemText :text="applicant.email2" />
                        </div>
                    </div>
                </b-card-body>

                <!-- ID Info Section -->
                <b-card-body class="p-2 d-flex flex-column border-bottom" style="row-gap: 12px">
                    <h4 class="mb-0">ID INFO</h4>
                    <div class="row">
                        <div class="col-3">
                            <label class="fw-medium mb-1" for="fname4">National ID</label>
                            <ItemText :text="applicant.additionalDetails.nationalID" />
                        </div>
                        <div class="col-3">
                            <label class="fw-medium mb-1" for="fname4">Passport</label>
                            <ItemText :text="applicant.additionalDetails.passport" />
                            <label class="fw-medium mb-1" for="fname4">Passport Exp. Date</label>
                            <ItemText :text="applicant.additionalDetails.passportExpirationDate" isDate />
                        </div>
                        <div class="col-3">
                            <label class="fw-medium mb-1" for="fname4">Driver's License</label>
                            <ItemText :text="applicant.additionalDetails.driverLicense" />
                            <label class="fw-medium mb-1" for="fname4">DL Exp. Date</label>
                            <ItemText :text="applicant.additionalDetails.driverLicenseExpirationDate" isDate />
                        </div>
                        <div class="col-3">
                            <label class="fw-medium mb-1" for="fname4">Cedula</label>
                            <ItemText :text="applicant.additionalDetails.cedula" />
                            <label class="fw-medium mb-1" for="fname4">Cedula Exp. Date</label>
                            <ItemText :text="applicant.additionalDetails.cedulaExpirationDate" isDate />
                        </div>
                    </div>
                </b-card-body>
                <!-- End Section -->

                <!-- Personalia Section -->

                <b-card-body class="p-2 d-flex flex-column border-bottom" style="row-gap: 12px">
                    <h4 class="mb-0">Personal Info</h4>
                    <div class="row">
                        <div class="col-3">
                            <label class="fw-medium mb-1" for="fname4">Place of Birth</label>
                            <ItemText :text="applicant.additionalDetails.placeOfBirthName" />
                        </div>
                        <div class="col-3">
                            <label class="fw-medium mb-1" for="fname4">Nationality</label>
                            <ItemText :text="applicant.additionalDetails.nationality" />
                        </div>
                        <div class="col-3">
                            <label class="fw-medium mb-1" for="fname4">Date of birth</label>
                            <ItemText :text="applicant.additionalDetails.birthDate" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-3">
                            <label class="fw-medium mb-1" for="comp4">Gender</label>
                            <ItemText :text="genderName" />
                        </div>
                        <div class="col-3">
                            <label class="fw-medium mb-1" for="comp4">Marital Status</label>
                            <ItemText :text="applicant.additionalDetails.maritalStatusName" />
                        </div>
                        <div class="col-3" v-if="applicant.additionalDetails.maritalStatusId == 1">
                            <label class="fw-medium mb-1" for="fname4">Spouses Full Name</label>
                            <ItemText :text="applicant.additionalDetails.spousesFullName" />
                        </div>
                        <div class="col-3">
                            <label class="fw-medium mb-1" for="comp4">Number of children</label>
                            <p class="mb-0">{{ applicant.additionalDetails.numberDependentPersons }}</p>
                            <!-- <ItemText :text="applicant.additionalDetails.numberDependentPersons" /> -->
                        </div>
                    </div>
                </b-card-body>
                <!-- End Section -->

                <!-- <b-card-body v-if="loan.loanTypeId == 2" class="p-2 border-bottom">
                    <h4>Vehicle Info</h4>
                    <div class="row" style="row-gap: 4px">
                        <div class="col-4">
                            <label class="fw-medium mb-1">Brand</label>
                            <ItemText>{{ dataSelected.brand }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">Model</label>
                            <ItemText>{{ dataSelected.model }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">Year</label>
                            <ItemText>{{ dataSelected.year }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">VIN</label>
                            <ItemText>{{ dataSelected.vin }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">Condition</label>
                            <ItemText>{{ dataSelected.condition }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">Color</label>
                            <ItemText>{{ dataSelected.color }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">Mileage</label>
                            <ItemText>{{ dataSelected.mileage }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">Price</label>
                            <ItemText>{{ dataSelected.price }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">Appraisal</label>
                            <ItemText>{{ dataSelected.appraisal }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">Seller Name</label>
                            <ItemText>{{ dataSelected.sellerName }}</ItemText>
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1">Seller Contact</label>
                            <ItemText>{{ dataSelected.sellerContact }}</ItemText>
                        </div>

                        <div class="col-4">
                            <label class="fw-medium mb-1">Seller Address</label>
                            <ItemText>{{ dataSelected.sellerAddress }}</ItemText>
                        </div>
                    </div>
                </b-card-body> -->

                <b-card-body class="p-2 border-bottom">
                    <h4 class="">EMPLOYMENT INFO</h4>
                    <div class="row border-bottom pb-2 mb-2">
                        <div class="col-2">
                            <label class="fw-medium mb-0" for="fname4">EMPLOYEER NAME</label>
                        </div>
                        <div class="col-2">
                            <label class="fw-medium mb-0" for="fname4">JOB DESCRIPTION</label>
                        </div>
                        <div class="col-2">
                            <label class="fw-medium mb-0" for="fname4">START DATE</label>
                        </div>
                        <div class="col-2">
                            <label class="fw-medium mb-0" for="fname4">END DATE</label>
                        </div>
                        <div class="col-2">
                            <label class="fw-medium mb-0" for="fname4">MONTHLY SALARY</label>
                        </div>
                    </div>
                    <!-- <div
                        v-for="employment in applicant.employments"
                        :key="employment.id"
                        class="row pb-2 mb-2"
                        style="row-gap: 12px"
                    >
                        <div class="col-2">
                            <ItemText :text="employment.employerName" />
                        </div>
                        <div class="col-2">
                            <ItemText :text="employment.jobDescription" />
                        </div>
                        <div class="col-2">
                            <ItemText :text="employment.fromDate" />
                        </div>
                        <div class="col-2">
                            <ItemText :text="employment.toDate" />
                        </div>
                        <div class="col-2">
                            <ItemText :text="employment.monthlySalary" />
                        </div>
                    </div> -->
                </b-card-body>

                <!-- Bank Info -->
                <b-card-body v-if="applicant.additionalDetails.bankId != -1" class="p-2 border-bottom">
                    <h4>Bank Accounts</h4>
                    <div class="row">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-4">
                                    <label class="fw-medium mb-1" for="comp4">#</label>
                                </div>
                                <div class="col-4">
                                    <label class="fw-medium mb-1" for="comp4">Bank</label>
                                </div>
                                <div class="col-4">
                                    <label class="fw-medium mb-1" for="comp4">Account Number</label>
                                </div>
                            </div>
                            <div class="row" v-if="applicant.additionalDetails.bankId != -1">
                                <div class="col-4">
                                    <ItemText :text="'1'" />
                                </div>
                                <div class="col-4">
                                    <ItemText :text="applicant.additionalDetails.bankName" />
                                </div>
                                <div class="col-4">
                                    <ItemText :text="applicant.additionalDetails.bankAccountNumber" />
                                </div>
                            </div>
                            <div class="row" v-if="applicant.additionalDetails.bankId2 != -1">
                                <div class="col-4">
                                    <ItemText :text="2" />
                                </div>
                                <div class="col-4">
                                    <ItemText :text="applicant.additionalDetails.bankName2" />
                                </div>
                                <div class="col-4">
                                    <ItemText :text="applicant.additionalDetails.bankAccountNumber2" />
                                </div>
                            </div>
                        </div>
                        <template v-if="applicant.additionalDetails.bankId != -1">
                            <div class="col-6"></div>
                        </template>
                    </div>
                </b-card-body>
                <!-- End Section -->

                <b-card-body class="p-2 border-bottom">
                    <h4>REFERENCES</h4>
                    <div class="row">
                        <div class="col-4" v-for="(reference, idx) in customer.references" :key="reference.id">
                            <h5>REFERENCE {{ idx + 1 }}</h5>
                            <div class="">
                                <label class="fw-medium mb-1" for="fname4">Name</label>
                                <ItemText :text="reference.name" />
                            </div>
                            <div class="">
                                <label class="fw-medium mb-1" for="fname4">Contact Number</label>
                                <ItemText :text="reference.contactNumber" />
                            </div>

                            <div class="">
                                <label class="fw-medium mb-1" for="fname4">Address</label>
                                <ItemText :text="reference.address" />
                            </div>

                            <div class="">
                                <label class="fw-medium mb-1" for="fname4">Relation to client</label>
                                <ItemText :text="reference.relationTypeDescription" />
                            </div>
                        </div>
                    </div>
                </b-card-body>

                <!-- Co Applicant Section -->

                <b-card-body class="p-2 border-bottom">
                    <div class="d-flex justify-content-between pb-2">
                        <h4>CO-APPLICANT INFO</h4>
                        <b-button
                            size="sm"
                            @click="goToCoApplicantDetail"
                            variant="primary"
                            :disabled="!coApplicant.id || coApplicant == 0"
                            >View Details
                        </b-button>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label class="fw-medium mb-1" for="fname4">Co Applicant Identification</label>
                            <ItemText :text="coApplicant.nationalID" />
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1" for="fname4">Co Applicant FirstName</label>
                            <ItemText :text="coApplicant.firstName" />
                        </div>

                        <div class="col-4">
                            <label class="fw-medium mb-1" for="fname4">Co Applicant LastName</label>
                            <ItemText :text="coApplicant.lastName" />
                        </div>

                        <div class="col-4">
                            <label class="fw-medium mb-1" for="fname4">Co Applicant Phone</label>
                            <ItemText :text="coApplicant.phone1" />
                        </div>
                    </div>
                </b-card-body>
                <!-- End Section -->

                <!-- Guarantor Section -->
                <b-card-body class="p-2 border-bottom">
                    <div class="d-flex justify-content-between pb-2">
                        <h4>GUARANTOR INFO</h4>
                        <b-button
                            size="sm"
                            @click="goToGuarantorDetail"
                            variant="primary"
                            :disabled="!guarantor.id || guarantor == 0"
                            >View Details
                        </b-button>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label class="fw-medium mb-1" for="fname4">Guarantor Identification</label>
                            <ItemText :text="guarantor.nationalID" />
                        </div>
                        <div class="col-4">
                            <label class="fw-medium mb-1" for="fname4">Guarantor FirstName</label>
                            <ItemText :text="guarantor.firstName" />
                        </div>

                        <div class="col-4">
                            <label class="fw-medium mb-1" for="fname4">Guarantor LastName</label>
                            <ItemText :text="guarantor.lastName" />
                        </div>

                        <div class="col-4">
                            <label class="fw-medium mb-1" for="fname4">Guarantor Phone</label>
                            <ItemText :text="guarantor.phone1" />
                        </div>
                    </div>
                </b-card-body>
                <!-- End Section -->
            </div>
        </b-card>
    </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '../customers/Header.vue';
import ItemText from '../ItemText.vue';
export default {
    name: 'Customer',
    components: {
        Header,
        ItemText,
    },
    props: {
        new: Boolean,
        readOnly: Boolean,
    },
    data: () => ({
        loading: false,
        maritalStatus: [
            {
                id: 1,
                name: 'Casa',
            },
            {
                id: 2,
                name: 'Soltero/-a',
            },
            {
                id: 3,
                name: 'Biba Hunto',
            },
            {
                id: 4,
                name: 'Otro',
            },
        ],
        applicant: null,
        coApplicant: null,
        guarantor: null,
    }),
    computed: {
        ...mapGetters({
            customer: 'customer/getCustomer',
            authorizations: 'auth/getAuthorizations',
            loanApplication: 'loan/applications/getLoanApplication',
            countries: 'general/countries/getCountries',
        }),
        hasPermission() {
            return this.authorizations.includes('/LoanApplications-Additional-Details');
        },
        maritalStatusName() {
            return this.maritalStatus.find((status) => status.id == this.applicant.additionalDetails.maritalStatusId)
                ?.name;
        },
        placeOfBirthName() {
            if (!this.applicant?.additionalDetails?.placeOfBirthId) return '';
            return this.countries.find((country) => country.id == this.applicant.additionalDetails.placeOfBirthId)
                ?.name;
        },
        genderName() {
            return this.applicant?.additionalDetails?.gender == 'F' ? 'Female' : 'Male';
        },
    },
    methods: {
        goToCoApplicantDetail() {
            this.$router.push(`/customers/list/${this.coApplicant.id}`);
        },
        goToGuarantorDetail() {
            this.$router.push(`/customers/list/${this.guarantor.id}`);
        },
        goToApplicantDetail() {
            this.$router.push(`/customers/list/${this.applicant.id}`);
        },
    },
    created() {
        this.applicant = this.loanApplication.applicantInfo;
        this.coApplicant = this.loanApplication.coApplicantInfo;
        this.guarantor = this.loanApplication.guarantorInfo;
    },
};
</script>
