<template>
    <div class="p-3" v-if="customer.id">
        <h3 class="text-center" style="font-size: 22px">{{ title }}</h3>
        <div class="pb-2 mb-2">
            <h5 class="">Personal Info</h5>
            <div class="row">
                <label class="fw-medium mb-0 col-6">National ID</label>
                <p class="mb-1 col-6">{{ customer.additionalDetails.nationalID }}</p>
            </div>
            <div class="row">
                <label class="fw-medium mb-0 col-6">First Name</label>
                <p class="mb-1 col-6">{{ customer.firstName }}</p>
            </div>
            <div class="row">
                <label class="fw-medium mb-0 col-6">Middle Name</label>
                <p class="mb-1 col-6">{{ customer.middleName }}</p>
            </div>
            <div class="row">
                <label class="fw-medium mb-0 col-6">Last Name</label>
                <p class="mb-1 col-6">{{ customer.lastName }}</p>
            </div>
            <div class="row mb-4">
                <label class="fw-medium mb-0 col-6">Address</label>
                <p class="mb-1 col-6">{{ customer.address1 }}</p>
            </div>

            <template v-if="customer.additionalDetails">
                <div class="row">
                    <label class="fw-medium mb-0 col-6">Place of Birth</label>
                    <p class="mb-1 col-6">{{ customer.additionalDetails.placeOfBirthName }}</p>
                </div>
                <div class="row">
                    <label class="fw-medium mb-0 col-6">Nationality</label>
                    <p class="mb-1 col-6">{{ customer.additionalDetails.nationality }}</p>
                </div>
                <div class="row">
                    <label class="fw-medium mb-0 col-6">Date of birth</label>
                    <p class="mb-1 col-6">{{ customer.additionalDetails.birthDate }}</p>
                </div>
            </template>
            <div class="row">
                <label class="fw-medium mb-0 col-6">Gender</label>
                <p class="mb-1 col-6">{{ genderName }}</p>
            </div>

            <div class="row">
                <label class="fw-medium mb-0 col-6">Marital Status</label>
                <p class="mb-1 col-6">{{ customer.additionalDetails.maritalStatusName }}</p>
            </div>

            <div class="row mb-4" v-if="customer.additionalDetails">
                <label class="fw-medium mb-0 col-6">Dependent Persons</label>
                <p class="mb-1 col-6">{{ customer.additionalDetails.numberDependentPersons }}</p>
            </div>

            <h4>Compliance</h4>

            <div class="row mb-4">
                <label class="fw-medium mb-0 col-6">Risk Rating</label>
                <p class="mb-1 col-6">{{ customer.riskTypeId }}</p>
            </div>

            <h4>Incomes</h4>
            <div class="row mb-4">
                <b-table
                    small
                    show-empty
                    striped
                    borderless
                    emp
                    :items="incomes"
                    :fields="[
                        { key: 'incomeId', label: 'ID' },
                        // { key: 'customerType', label: 'Customer Type' },
                        { key: 'incomeDescription', label: 'Description' },
                        { key: 'amount', label: 'Amount' },
                    ]"
                ></b-table>
            </div>

            <h4>Expenses</h4>
            <div class="row mb-4">
                <label class="fw-medium mb-0 col-6">Cost of Living</label>
                <p class="mb-1 col-6"></p>
            </div>

            <div class="row mb-4">
                <b-table
                    small
                    show-empty
                    striped
                    borderless
                    emp
                    :items="expenses"
                    :fields="[
                        { key: 'expenseId', label: 'ID' },
                        { key: 'customerType', label: 'Customer Type' },
                        { key: 'expenseDescription', label: 'Description' },
                        { key: 'amount', label: 'Amount' },
                    ]"
                ></b-table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'Customer',
    props: {
        customerType: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        loading: true,
        customer: null,

        maritalStatus: [
            {
                id: 1,
                name: 'Casa',
            },
            {
                id: 2,
                name: 'Soltero/-a',
            },
            {
                id: 3,
                name: 'Biba Hunto',
            },
            {
                id: 4,
                name: 'Otro',
            },
        ],
        incomes: [],
        expenses: [],
    }),
    computed: {
        ...mapGetters({
            currentCustomer: 'customer/getCustomer',
            loan: 'loan/applications/getLoan',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        title() {
            if (this.customerType === 'coApplicant') {
                return 'Co-Applicant';
            }
            if (this.customerType === 'guarantor') {
                return 'Guarantor';
            }
            return 'Applicant';
        },
        genderName() {
            if (this.customer?.additionalDetails?.gender === 'M') return 'Male';
            return 'Female';
        },
        maritalStatusName() {
            return (
                this.maritalStatus.find((status) => status.id === this.customer.additionalDetails?.maritalStatusId)
                    ?.name ?? 'NA'
            );
        },
    },
    methods: {
        async setData() {
            if (this.customerType === 'applicant') {
                this.customer = this.loanApplication.applicantInfo;
                this.incomes = this.loanApplication.incomes.filter((income) => income.customerType === 'Applicant');
                this.expenses = this.loanApplication.expenses.filter((expense) => expense.customerType === 'Applicant');
            }
            if (this.customerType === 'coApplicant') {
                this.customer = this.loanApplication.coApplicantInfo;
                this.incomes = this.loanApplication.incomes.filter((income) => income.customerType === 'Co-Applicant');
                this.expenses = this.loanApplication.expenses.filter(
                    (expense) => expense.customerType === 'Co-Applicant',
                );
            }
            if (this.customerType === 'guarantor') {
                this.customer = this.loanApplication.guarantorInfo;
                this.incomes = this.loanApplication.incomes.filter((income) => income.customerType === 'Guarantor');
                this.expenses = this.loanApplication.expenses.filter((expense) => expense.customerType === 'Guarantor');
            }
        },
    },
    created() {
        this.setData();
    },
};
</script>
