<template>
    <div v-if="customer && customer.id != 0">
        <h5>{{ customer.firstName }} {{ customer.lastName }}</h5>

        <div v-for="item in debtsAndIncomes" :key="item.id" class="d-flex justify-content-between">
            <label>{{ item.description }}</label>
            <ItemText :text="item.amount" isCurrency />
        </div>

        <div class="d-flex justify-content-between border-top pt-3 mt-3">
            <label>Remaining Income</label>
            <ItemText :text="remainingIncome" isCurrency />
        </div>
        <div class="d-flex justify-content-between">
            <label>Debt Ratio</label>
            <ItemText :text="debtRatio + '%'" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ItemText from '../../ItemText.vue';
export default {
    name: 'IndividualSummary',
    components: {
        ItemText,
    },
    props: {
        customerType: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        loading: true,
        customer: null,
    }),
    computed: {
        ...mapGetters({
            currentCustomer: 'customer/getCustomer',
            loan: 'loan/applications/getLoan',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        debtsAndIncomes() {
            return this.customer?.individualSummaryInfo?.debtsAndIncomes ?? [];
        },
        debtRatio() {
            return this.customer?.individualSummaryInfo?.debtRatio ?? 0;
        },
        remainingIncome() {
            return this.customer?.individualSummaryInfo?.remainingIncome ?? 0;
        },
    },
    methods: {
        async setData() {
            if (this.customerType === 'applicant') {
                this.customer = this.loanApplication.applicantInfo;
            }
            if (this.customerType === 'coApplicant') {
                this.customer = this.loanApplication.coApplicantInfo;
            }
            if (this.customerType === 'guarantor') {
                this.customer = this.loanApplication.guarantorInfo;
            }
        },
    },
    created() {
        this.setData();
    },
};
</script>
